
<template>
  <div>
    <!-- <label class="switch">
      <input type="checkbox" @click="popupActive=true" :checked="value == '1'" />
      <span class="slider round"></span>
    </label>-->

    <b-form-checkbox
      @click.native="popupActive=true"
      value="1"
      unchecked-value="0"
      v-model="form.advance_isActive"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS'+params.data.organization_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="User Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Tally Status ?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button color="success" type="filled" @click="onChangeCheckbox()" class="mr-3">Yes</vs-button>
              <vs-button color="danger" type="filled" @click="privStatus()">No</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import OrganizationService from "@/services/OrganizationService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        advance_isActive: "1"
      },
      copy_advance_isActive: null
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_advance_isActive = this.form.advance_isActive;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.organization_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function() {

      this.$vs.loading();
      // this.value = this.value === "1" ? "2" : "1";
      let id = this.params.data.organization_id;


      this.form.organization_type = id>1?this.form.organization_type.organization_type_id:1;


      let payload = {
        ...this.form
      };

      OrganizationService.editOrganization(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.error) {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.advance_isActive = this.copy_advance_isActive;
    }
  }
});
</script>
