<template>
  <div>
    <span class="text-center table-edit-icon" @click="popupActive=true">
      <i v-if="params.data.user_active == 4" class="text-center material-icons">unarchive</i>
      <i v-else class="text-center material-icons">archive</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalA'+params.data.user_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="User Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5 v-if="params.data.user_active == '4'">Do you want to unarchive?</h5>
          <h5 v-else-if="params.data.user_active == '1'||'2'">Do you want to archive?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
              >Yes</vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">No</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import userService from "@/services/UserService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        user_active: "4"
      },
      copy_user_active: null
    };
  },
  beforeMount() {},
  beforeDestroy() {
    let _id = document.getElementById("popmodalA" + this.params.data.user_id);
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      console.log("this.params", this.params);
      let id = this.params.data.user_id;
      let payload = {
        user_active: this.params.data.user_active == "4" ? "1" : "4"
      };
      userService
        .EditUser(payload, id)
        .then(response => {
          this.$vs.loading.close();
          const { data } = response;
          if (!data.error) {
            this.$vs.notify({
              title: "Updated!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success"
            });
            setTimeout(() => {
              eventBus.$emit("refreshorganizationTable", data);
            }, 1);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.user_active = this.copy_user_active;
    }
  }
});
</script>
