<template>
  <div>
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.organization_id"
        class="holamundo editModal"
        title="Update Organization"
        :active.sync="popupActive"
      >
        <form ref="testingRef">
          <FocusLock>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <!-- name -->
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Name"
                        v-model="form.organization_name"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Name')"
                      >{{ errors.first("Name") }}</span>
                    </div>
                  </div>
                </div>
                <!-- email-->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Email *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <!-- v-validate="'required'" -->
                      <vs-input
                        disabled
                        name="Email"
                        v-model="form.organization_email"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('name')"
                      >{{ errors.first("name") }}</span>
                    </div>
                  </div>
                </div>
                <!-- Phone -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Phone *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required|numeric|max:14'"
                        name="Phone"
                        v-model="form.organization_mobile"
                        class="w-full"
                        rules="alpha"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Phone')"
                      >{{ errors.first("Phone") }}</span>
                    </div>
                  </div>
                </div>
                <!-- address  -->
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Address *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-textarea v-model="form.organization_address" class="mb-0" rows="4" />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Address')"
                      >{{ errors.first("Address") }}</span>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Organization Type *</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.organization_type"
                        track-by="organization_type_id"
                        label="organization_type_name"
                        :options="organization_type_array"
                        name="Organization Type"
                        :searchable="false"
                        :disabled="checkArrayDisable"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{
                          option.organization_type_name
                          }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Domain Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Name"
                        v-model="form.domainName"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Name')"
                      >{{ errors.first("Name") }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Host Name</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="hostName"
                        v-model="form.hostName"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('hostName')"
                      >{{ errors.first("hostName") }}</span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-4 text-right align-self-center">
                    <h5 class="mb-0">Primary Color</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="primaryColor"
                        v-model="form.primaryColor"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('primaryColor')"
                      >{{ errors.first("primaryColor") }}</span>
                    </div>
                  </div>
                </div>

                <!-- gender -->

                <!-- DOB-->

                <!-- Password -->
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <!-- :disabled="submitStatus" -->
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                >Update</vs-button>

                <!-- <vs-button color="warning" type="border" class @click.prevent="clearForm">Reset</vs-button> -->
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import OrganizationService from "@/services/OrganizationService.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default Vue.extend({
  components: {
    flatPickr,
  },
  data() {
    return {
      configDOB: {
        maxDate: new Date(),
        dateFormat: "d-m-Y",
        // altFormat: "d-m-Y",
        // altInput: true
      },
      submitStatus: true,
      isPasswordUpdated: false,
      form: {
        organization_name: "",
        organization_email: "",
        organization_address: "",
        organization_mobile: "",
        organization_type: "",
        domainName: "",
        hostName: "",
        primaryColor: "",
      },
      user_password: null,
      users: [],
      popupActive: false,
      params: null,
      value: null,
      count: 0,
      checkArrayDisable: false,
      organization_type_array: [
        {
          organization_type_name: "Kozo",
          organization_type_id: "0",
        },
        {
          organization_type_name: "Contract",
          organization_type_id: "1",
        },
        {
          organization_type_name: "Both",
          organization_type_id: "2",
        },
      ],
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
    user_password: {
      handler: function (newVal, oldVal) {
        if (this.count > 0) {
          this.isPasswordUpdated = true;
          this.submitStatus = false;
        }
        this.count++;
      },
      deep: true,
    },
  },
  beforeMount() {
    this.value = this.params.value;
    this.form = this.params.data;
  },
  mounted() {
    console.log(this.params.data, "fsdfds");
    if (this.params.data.organization_type == 0) {
      this.organization_type_array.splice(1, 1);
    } else if (this.params.data.organization_type == 1) {
      this.organization_type_array.splice(0, 1);
    } else if (this.params.data.organization_type == 2) {
      // this.organization_type_array.splice(0, 1);
      // this.organization_type_array.splice(1, 1);
      // console.log(this.organization_type_array);

      this.checkArrayDisable = true;
    }
    this.form.organization_type = this.organization_type_array.filter((x) => {
      return (
        x.organization_type_id == this.params.data.organization_type.toString()
      );
    })[0];
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodal" + this.params.data.organization_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      // delete this.form.user_email;
      console.log("object", this.form);
      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        console.log("result", result);
        this.form.organization_type = this.form.organization_type.organization_type_id;
        if (result) {
          let id = this.params.data.organization_id;
          let payload = {
            ...this.form,
          };
          //   close;
          // console.log("this.isPasswordUpdated", this.isPasswordUpdated);

          OrganizationService.editOrganization(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              this.submitStatus = false;
              const { data } = response;
              this.popupActive = false;
              if (!data.error) {
                this.$vs.notify({
                  title: "Updated!",
                  text: data.data.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                setTimeout(() => {
                  eventBus.$emit("refreshorganizationTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                console.log("data :", data);
                this.$vs.notify({
                  title: "Error!",
                  text: data.error.message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.submitStatus = true;
              console.log("error:", error);
            });
        } else {
          //form have errors
        }
      });
    },
    getUserList: function () {
      // console.log("RolesService :", RolesService);
      OrganizationService.editUser()
        .then((response) => {
          const { data } = response;
          if (data.err) {
          } else {
            this.users = data.data;
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },
});
</script>
